<template>
	<v-container fluid class="px-10">
		<v-row>
			<v-col cols="12">
				<h1 color="white">Team</h1>
				<v-divider class="my-3"></v-divider>
			</v-col>

			<v-col cols="12" v-if="teamLoading">
				<v-row>
					<v-col sm="12" md="3" lg="3" xl="3" cols="12" v-for="(item, index) in placeholder" :key="index">
						<v-skeleton-loader height="400" class="mx-auto rounded-xl" type="card"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-col>

			<c-col md="6" sm="12" lg="4" xl="4" cols="12" v-if="team.message" class="mx-auto">
				<h4>{{ team.message }}</h4>
			</c-col>

			<v-col v-else-if="!teamLoading" md="6" sm="12" lg="4" xl="4" cols="12" v-for="streamer in team" :key="streamer.id" >
				<v-card 
					flat 
					tile
					target="_blank"
					:href="`https://www.twitch.tv/${streamer.login}`"
					:img="streamer.is_live ? `https://static-cdn.jtvnw.net/previews-ttv/live_user_${streamer.login}-1920x1080.jpg` : streamer.offline_image_url" 
					class="rounded-xl pa-0 d-flex justify-center align-stretch fill-height mgn_card"
					v-bind:class="{ 'is_live': streamer.is_live }"
				>
					<v-col cols="12" class="pa-0 overflow-hidden">
						<v-col cols="12" class="d-flex justify-center">
							<v-img 
								max-width="130" 
								class="profile_pic" 
								v-bind:class="{ 'is_live': streamer.is_live }"
								:src="streamer.profile_image_url"
								transition="scale-transition">
							</v-img>
						</v-col>

						<v-card-title class="d-flex justify-center">
							<h3>{{ streamer.display_name }}</h3>
						</v-card-title>

						<v-card-actions class="d-flex justify-center">
							<div v-for="social in streamer.social_links" :key="social.type">
								<v-btn elevation="0" color="primary" class="ma-2" fab :href="social.url" target="_blank">
									<font-awesome-icon v-if="social.type.toUpperCase() === 'discord'.toUpperCase()" icon="fa-brands fa-discord" size="lg"/>
									<font-awesome-icon v-else-if="social.type.toUpperCase() === 'tiktok'.toUpperCase()" icon="fa-brands fa-tiktok" size="lg"/>
									<v-icon v-else>mdi-{{ social.type.toLowerCase() }}</v-icon>
								</v-btn>
							</div>
						</v-card-actions>

						<v-col cols="12" v-if="streamer.description !== ''" class="d-flex justify-center align-start mgn_header fill-height">
							<p class="ma-0">{{ streamer.description }}</p>
						</v-col>
					</v-col>
				</v-card>
			</v-col>
			<v-col v-else-if="error">
				<h2>Error loading streamers. :(</h2>
			</v-col>

			<v-col cols="12" v-if="partners.length > 0">
				<h2>Partners</h2>
			</v-col>

			<v-col cols="12" v-if="partnersLoading">
				<v-row>
					<v-col sm="12" md="3" lg="4" xl="3" cols="12" v-for="(item, index) in placeholder" :key="index">
						<v-skeleton-loader height="400" class="mx-auto rounded-xl" type="card"></v-skeleton-loader>
					</v-col>
				</v-row>
			</v-col>

			<v-col v-else-if="!partnersLoading" md="6" sm="12" lg="4" xl="3" cols="12" v-for="streamer in partners" :key="streamer.id">
				<v-card flat tile min-height="500" class="rounded-xl pa-0 d-flex justify-center align-start align-self-stretch fill-height">
					<v-col cols="12">

						<v-col cols="12" class="d-flex justify-center">
							<v-img max-width="110" class="profile_pic" :src="streamer.profile_pic" transition="scale-transition"></v-img>
						</v-col>

						<v-card-title primary-title class="d-flex justify-center">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn elevation="0" v-bind="attrs" v-on="on" large color="dark" class="ma-2" :href="streamer.channel_url" target="_blank">
										{{ streamer.username }}
										<StatusIndicator class="ml-5 mr-2" v-if="streamer.is_live" positive pulse></StatusIndicator>
										<StatusIndicator class="ml-5 mr-2" v-else negative></StatusIndicator>
										<v-icon class="ml-1">mdi-open-in-new</v-icon>
									</v-btn>
								</template>
								<span v-if="streamer.is_live">Stream is online! &#69705; {{ streamer.current_viewers }} viewers</span>
								<span v-else>Stream is offline. &#69705; {{ streamer.current_viewers }} viewers</span>
							</v-tooltip>
							<v-btn color="primary" icon large elevation="0" :href="streamer.url">
								<v-icon>mdi-home</v-icon>
							</v-btn>
						</v-card-title>

						<v-card-actions class="d-flex justify-center">
							<div v-for="social in streamer.social_links" :key="social.type">
								<v-btn elevation="0" color="primary" class="ma-2" fab :href="social.url" target="_blank">
									<font-awesome-icon v-if="social.type.toUpperCase() === 'discord'.toUpperCase()" icon="fa-brands fa-discord" size="lg"/>
									<font-awesome-icon v-else-if="social.type.toUpperCase() === 'tiktok'.toUpperCase()" icon="fa-brands fa-tiktok" size="lg"/>
									<v-icon v-else>mdi-{{ social.type.toLowerCase() }}</v-icon>
								</v-btn>
							</div>
						</v-card-actions>

						<v-col cols="12" class="d-flex justify-center">
							<p>{{ streamer.streamer_info }}</p>
						</v-col>
					</v-col>
				</v-card>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
import { StatusIndicator } from 'vue-status-indicator';
import 'vue-status-indicator/dist/vue-status-indicator.css';

export default {
	components: {
		StatusIndicator
	},

	data() {
		return {
			placeholder: 4,
			team: [],
			partners: [],
			teamLoading: true,
			partnersLoading: true,
			error: false,
		}
	},

	async created() {
		await this.fetchCore();
	},

	async mounted() {
		await this.fetchPartners();
	},

	methods: {
		async fetchCore () {
			try {
				const request = await fetch(`${this.$store.state.base_url}/team`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.streams) {
						this.team = res.streams;
					}
					else {
						this.team = res;
					}

					this.teamLoading = false;
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
							this.loadingLoginBtn = false;
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.error = true;
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'An unexpected error occured.'
				});
			}
		},

		async fetchPartners () {
			try {
				const request = await fetch(`${this.$store.state.base_url}/team/partners`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('mgn_sessid'),
					}
				});
				
				const res = await request.json();

				if (request.ok) {
					if (res.partners) {
						this.partners = res.partners;
					}
					this.partnersLoading = false;
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
							this.loadingLoginBtn = false;
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.error = true;
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: 'An unexpected error occured.'
				});
			}
		}
	},
}
</script>

<style scoped>
.is_live {
	border: 5px solid #19CA6E;
	box-shadow: 5px solid #19CA6E;
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(25, 202, 110, 0.7);
	}

	70% {
		box-shadow: 0 0 0 15px rgba(25, 202, 110, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(25, 202, 110, 0);
	}
}
</style>